<template>
  <el-container>
      <el-main style="margin-top: 20px">
        <div style="text-align: center;margin:0 auto;">
          <h1 v-if="status==0">已关闭</h1>
          <h1 v-if="status==1">已开启</h1>
          <el-button @click="enablePage(1)" type="primary">开启</el-button>
          <el-button @click="enablePage(0)" type="success">关闭</el-button>
        </div>
      </el-main>
  </el-container>
</template>
<script>

export default {
  data() {
    return {
      status:0
    };
  },
  // 卸载
  beforeDestroy() {
  },
  beforeCreate(){
  },
  created() {
    this.queryEnablePage();
  },
  mounted() {
  },
  methods: {
    enablePage(status){
      this.$axios.get(this.$gameApiurls.jnhEnablePage+"?status="+status)
        .then((res) => {
          this.status = res.data;
        });
    },
    queryEnablePage(){
      this.$axios.get(this.$gameApiurls.queryEnablePage)
        .then((res) => {
          this.status = res.data;
        });
    }
  },
};
</script>

<style scope>
body {
  height: 100vh;
  background-color: #f4fafe;
}

</style>
